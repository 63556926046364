import { groq } from 'next-sanity'

export const vimeoVideoObjectFragment = groq`
  name,
  link,
  width,
  height,
  files[] {
    width,
    height,
  },
`

export const videoFragment = groq`
  type,
  "vimeoVideo": *[
    _type == "vimeo.videoAsset" &&
    ^.source.id == _id
  ][0] {
    ${vimeoVideoObjectFragment}
  },
  muxVideo {
    asset->,
  },
  settings,
  aspectRatio,
  subtitles {
    ...,
    "url": asset->url,
  },
  borderRadius,
`
