import dynamic from 'next/dynamic'

import {
  type SanityBlogAuthorPostGridModule,
  type SanityBlogCategoryPostGridModule,
  type SanityBlogPostBodyModule,
  type SanityBlogPostCommentWidgetModule,
  type SanityBlogPostGridModule,
  type SanityBlogPostHeaderModule,
  type SanityBlogPostItemModule,
  type SanityBlogPostReadMoreModule,
  type SanityDictionaryEntryBodyModule,
  type SanityDictionaryEntryHeaderModule,
  type SanityDictionaryEntryListModule,
  type SanityDividerPhotoModule,
  type SanityGridModule,
  type SanityHeroModule,
  type SanityImageAndTextModule,
  type SanityLogoGrid,
  type SanityMarqueeModule,
  type SanityMaskedCircleSectionModule,
  type SanityMediaHeroModule,
  type SanityModule,
  type SanityOpenRoleBodyModule,
  type SanityOpenRoleHeaderModule,
  type SanityOpenRolesGridModule,
  type SanityPredefinedPageModuleModule,
  type SanityPricingPlansModule,
  type SanityTeamMembersGridModule,
  type SanityVideoModule,
} from '@data/sanity/queries/types/modules'

const BlogPostBody = dynamic(() => import('./blog/blog-post-body'))
const BlogPostCommentWidget = dynamic(
  () => import('./blog/blog-post-comment-widget')
)
const BlogPostGrid = dynamic(() => import('./blog/blog-post-grid'))
const BlogPostHeader = dynamic(() => import('./blog/blog-post-header'))
const BlogPostItem = dynamic(() => import('./blog/blog-post-item'))
const BlogPostReadMore = dynamic(() => import('./blog/blog-post-read-more'))
const DictionaryEntryBody = dynamic(
  () => import('./dictionary/dictionary-entry-body')
)
const DictionaryEntryHeader = dynamic(
  () => import('./dictionary/dictionary-entry-header')
)
const DictionaryEntryList = dynamic(
  () => import('./dictionary/dictionary-entry-list')
)
const DividerPhoto = dynamic(() => import('./divider-photo'))
const Grid = dynamic(() => import('./grid'))
const Hero = dynamic(() => import('./hero'))
const ImageAndText = dynamic(() => import('./image-and-text'))
const LogoGrid = dynamic(() => import('./logo-grid'))
const Marquee = dynamic(() => import('./marquee'))
const MaskedCircleSection = dynamic(() => import('./masked-circle-section'))
const MediaHero = dynamic(() => import('./media-hero'))
const OpenRoleBody = dynamic(() => import('./careers/open-role-body'))
const OpenRoleHeader = dynamic(() => import('./careers/open-role-header'))
const OpenRolesGrid = dynamic(() => import('./careers/open-roles-grid'))
const PricingPlans = dynamic(() => import('./pricing-plans'))
const TeamMembersGrid = dynamic(() => import('./team-members-grid'))
const VideoModule = dynamic(() => import('./video'))

interface ModuleProps {
  module: SanityModule
}

const Module = ({ module }: ModuleProps) => {
  switch (module._type) {
    case 'blogAuthorPostGrid': {
      const { posts, options } = module as SanityBlogAuthorPostGridModule

      return <BlogPostGrid posts={posts} options={options} />
    }

    case 'blogCategoryPostGrid': {
      const { posts, options } = module as SanityBlogCategoryPostGridModule

      return <BlogPostGrid posts={posts} options={options} />
    }

    case 'blogPostBody': {
      const { content } = module as SanityBlogPostBodyModule

      return <BlogPostBody content={content} />
    }

    case 'blogPostCommentWidget': {
      const { service, post } = module as SanityBlogPostCommentWidgetModule

      if (!post) {
        return null
      }

      return <BlogPostCommentWidget service={service} post={post} />
    }

    case 'blogPostGrid': {
      const { posts, options } = module as SanityBlogPostGridModule

      return <BlogPostGrid posts={posts} options={options} />
    }

    case 'blogPostHeader': {
      const { post, options } = module as SanityBlogPostHeaderModule

      if (!post) {
        return null
      }

      return <BlogPostHeader post={post} options={options} />
    }

    case 'blogPostItem': {
      const { post, options, background, spacing } =
        module as SanityBlogPostItemModule

      return (
        <BlogPostItem
          post={post}
          options={options}
          background={background}
          spacing={spacing}
        />
      )
    }

    case 'blogPostReadMore': {
      const { posts } = module as SanityBlogPostReadMoreModule

      return <BlogPostReadMore posts={posts} />
    }

    case 'dictionaryEntryList': {
      const { dictionaryEntries } = module as SanityDictionaryEntryListModule

      return <DictionaryEntryList dictionaryEntries={dictionaryEntries} />
    }

    case 'dictionaryEntryBody': {
      const { content } = module as SanityDictionaryEntryBodyModule

      return <DictionaryEntryBody content={content} />
    }

    case 'dictionaryEntryHeader': {
      const { entry, options } = module as SanityDictionaryEntryHeaderModule

      if (!entry) {
        return null
      }

      return <DictionaryEntryHeader entry={entry} options={options} />
    }

    case 'dividerPhoto': {
      const { photo } = module as SanityDividerPhotoModule

      return <DividerPhoto photo={photo} />
    }

    case 'grid': {
      const {
        columns,
        size,
        spacing,
        background,
        noColumnGaps,
        noRowGaps,
        reverseSequence,
      } = module as SanityGridModule

      return (
        <Grid
          columns={columns}
          size={size}
          spacing={spacing}
          background={background}
          noColumnGaps={noColumnGaps}
          noRowGaps={noRowGaps}
          reverseSequence={reverseSequence}
        />
      )
    }

    case 'hero': {
      const { tagline, title, content, graphicType, image, lottieAssetUrl } =
        module as SanityHeroModule

      return (
        <Hero
          tagline={tagline}
          title={title}
          content={content}
          graphicType={graphicType}
          image={image}
          lottieAssetUrl={lottieAssetUrl}
        />
      )
    }

    case 'imageAndText': {
      const { image, textContent, textPosition, backgroundColor } =
        module as SanityImageAndTextModule

      return (
        <ImageAndText
          image={image}
          textContent={textContent}
          textPosition={textPosition}
          backgroundColor={backgroundColor}
        />
      )
    }

    case 'logoGrid': {
      const { title, logos, spacing, background, noItemGaps, reverseSequence } =
        module as SanityLogoGrid

      return (
        <LogoGrid
          title={title}
          logos={logos}
          spacing={spacing}
          background={background}
          noItemGaps={noItemGaps}
          reverseSequence={reverseSequence}
        />
      )
    }

    case 'marquee': {
      const {
        content,
        contentAlignment,
        items,
        speed,
        reverse,
        pausable,
        spacing,
        background,
        reverseSequence,
      } = module as SanityMarqueeModule

      return (
        <Marquee
          content={content}
          contentAlignment={contentAlignment}
          items={items}
          speed={speed ?? 0.5}
          reverse={reverse}
          pausable={pausable}
          spacing={spacing}
          background={background}
          reverseSequence={reverseSequence}
        />
      )
    }

    case 'maskedCircleSection': {
      const { image, content, colors } =
        module as SanityMaskedCircleSectionModule

      return (
        <MaskedCircleSection image={image} content={content} colors={colors} />
      )
    }

    case 'mediaHero': {
      const { bgType, contentPosition, content, photos, muxVideo, vimeoVideo } =
        module as SanityMediaHeroModule

      return (
        <MediaHero
          bgType={bgType}
          contentPosition={contentPosition}
          content={content}
          photos={photos}
          muxVideo={muxVideo}
          vimeoVideo={vimeoVideo}
          overlay
        />
      )
    }

    case 'openRoleBody': {
      const { content } = module as SanityOpenRoleBodyModule

      return <OpenRoleBody content={content} />
    }

    case 'openRoleHeader': {
      const { openRole, options } = module as SanityOpenRoleHeaderModule

      if (!openRole) {
        return null
      }

      return <OpenRoleHeader openRole={openRole} options={options} />
    }

    case 'openRolesGrid': {
      const { title, description, openRoles } =
        module as SanityOpenRolesGridModule

      return (
        <OpenRolesGrid
          title={title}
          description={description}
          openRoles={openRoles}
        />
      )
    }

    case 'pricingPlans': {
      const { plans } = module as SanityPricingPlansModule

      return <PricingPlans plans={plans} />
    }

    case 'predefinedPageModule': {
      const { pageModules } = module as SanityPredefinedPageModuleModule

      return (
        <>
          {pageModules?.map((pageModule) => (
            <Module key={pageModule._key} module={pageModule} />
          ))}
        </>
      )
    }

    case 'teamMembersGrid': {
      const { title, subtitle, teamMembers } =
        module as SanityTeamMembersGridModule

      return (
        <TeamMembersGrid
          title={title}
          subtitle={subtitle}
          teamMembers={teamMembers}
        />
      )
    }

    case 'video': {
      const {
        type,
        vimeoVideo,
        muxVideo,
        settings,
        aspectRatio,
        subtitles,
        borderRadius,
      } = module as SanityVideoModule

      return (
        <VideoModule
          type={type}
          vimeoVideo={vimeoVideo}
          muxVideo={muxVideo}
          settings={settings}
          aspectRatio={aspectRatio}
          subtitles={subtitles}
          borderRadius={borderRadius}
        />
      )
    }
  }
}

export default Module
