import { groq } from 'next-sanity'

import { blockFragment } from './blocks'
import { blogPostOptionsFragment, blogPostWithoutBodyFragment } from './blog'
import {
  openRoleFragment,
  openRoleOptionsFragment,
  openRolePreviewFragment,
} from './careers'
import { complexContentFragment, simpleContentFragment } from './content'
import {
  dictionaryEntryOptionsFragment,
  dictionaryEntryPreviewFragment,
} from './dictionary'
import { imageFragment } from './image'
import { videoFragment, vimeoVideoObjectFragment } from './video'

const imageAndTextModuleFragment = groq`
  image {
    ${imageFragment}
  },
  textContent[] {
    ${complexContentFragment}
  },
  textPosition,
  backgroundColor,
`

const gridModuleFragment = groq`
  size,
  columns[] {
    _key,
    sizes[] {
      breakpoint,
      width,
      justify,
      align,
      start,
      spacing,
    },
    blocks[] {
      ${blockFragment}
    },
    background,
    borderRadius,
  },
  spacing,
  background,
  noColumnGaps,
  noRowGaps,
  reverseSequence,
`

const mediaHeroModuleFragment = groq`
  bgType,
  content[] {
    ${complexContentFragment}
  },
  contentPosition,
  photos {
    mobilePhoto {
      ${imageFragment}
    },
    desktopPhoto {
      ${imageFragment}
    },
  },
  muxVideo {
    asset->,
  },
  "vimeoVideo": *[
    _type == "vimeo.videoAsset" &&
    ^.video.id == _id
  ][0] {
    ${vimeoVideoObjectFragment}
  },
`

const heroModuleFragment = groq`
  tagline,
  title,
  content[] {
    ${simpleContentFragment}
  },
  graphicType,
  !defined(graphicType) || graphicType == "image" => {
    image {
      ${imageFragment}
    },
  },
  graphicType == "lottieAnimation" => {
    "lottieAssetUrl": lottieFile.asset->url,
  },
`

const dividerPhotoModuleFragment = groq`
  photo {
    ${imageFragment}
  },
`

const simpleMarqueeItemFragment = groq`
  text,
`

const photoMarqueeItemFragment = groq`
  "photo": {
    ${imageFragment}
  },
`

const marqueeModuleFragment = groq`
  content[] {
    ${complexContentFragment}
  },
  contentAlignment,
  speed,
  reverse,
  pausable,
  items[] {
    _key,
    _type,
    _type == 'simple' => {
      ${simpleMarqueeItemFragment}
    },
    _type == 'photo' => {
      ${photoMarqueeItemFragment}
    },
  },
  spacing,
  background,
  reverseSequence,
`

const logoGridModuleFragment = groq`
  title,
  "logos": logos[] {
    "url": image.asset->url,
    alt
  },
  spacing,
  background,
  noItemGaps,
  reverseSequence,
`

const blogPostGridModuleFragment = groq`
  "posts": *[
    _type == "blogPost" &&
    type != "template" &&
    (!defined(^.author) || author->slug.current == ^.author->slug.current) &&
    (!defined(^.category) || categories[]->slug.current match ^.category->slug.current)
  ]
    | order(_createdAt desc)
    | order(date desc)
  {
    ${blogPostWithoutBodyFragment}
  },
  options {
    ${blogPostOptionsFragment}
  },
`

const blogPostHeaderModuleFragment = groq`
  $blogPostSlug != null => {
    "post": *[
      _type == "blogPost" &&
      type != "template" &&
      slug.current == $blogPostSlug
    ]
      | order(_updatedAt desc)
    [0] {
      ${blogPostWithoutBodyFragment}
    },
  },
  options {
    ${blogPostOptionsFragment}
  },
`

const blogPostItemModuleFragment = groq`
  post->{
    ${blogPostWithoutBodyFragment}
  },
  options {
    ${blogPostOptionsFragment}
  },
  background,
  spacing,
`

const blogPostBodyModuleFragment = groq`
  content[] {
    ${complexContentFragment}
  },
`

const blogPostCommentWidgetModuleFragment = groq`
  $blogPostSlug != null => {
    "post": *[
      _type == "blogPost" &&
      type != "template" &&
      slug.current == $blogPostSlug
    ]
      | order(_updatedAt desc)
    [0] {
      ${blogPostWithoutBodyFragment}
    },
  },
  service,
`

const blogPostReadMoreModuleFragment = groq`
  $blogPostSlug != null => {
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      slug.current != $blogPostSlug
    ] {
      "matchingCategoryCount": count((categories[]._ref)[
        @ in *[
          _type == "blogPost" &&
          type != "template" &&
          slug.current == $blogPostSlug
        ]
          | order(_updatedAt desc)
        [0].categories[]._ref
      ]),
      ${blogPostWithoutBodyFragment}
    }
      | order(_createdAt desc)
      | order(date desc)
      | order(matchingCategoryCount desc)
    [0...5],
  },
`

const blogCategoryPostGridGridModuleFragment = groq`
  $blogCategorySlug != null => {
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      categories[]->slug.current match $blogCategorySlug
    ]
      | order(_createdAt desc)
      | order(date desc)
    {
      ${blogPostWithoutBodyFragment}
    },
  },
  options {
    ${blogPostOptionsFragment}
  },
`

const blogAuthorPostGridModuleFragment = groq`
  $blogAuthorSlug != null => {
    "posts": *[
      _type == "blogPost" &&
      type != "template" &&
      author->slug.current == $blogAuthorSlug
    ]
      | order(_createdAt desc)
      | order(date desc)
    {
      ${blogPostWithoutBodyFragment}
    },
  },
  options {
    ${blogPostOptionsFragment}
  },
`

const maskedCircleSectionFragment = groq`
  image {
    ${imageFragment}
  },
  content[] {
    ${complexContentFragment}
  },
  colors {
    backgroundPrimary,
    backgroundSecondary,
    text,
  },
`

const pricingPlansModuleFragment = groq`
  plans[] {
    name,
    price,
    description,
    featured,
    features,
    button {
      label,
      url,
      "page": page->{
        "id": _id,
        "type": _type,
        "slug": slug.current,
      },
    },
  },
`

const dictionaryEntryListModuleFragment = groq`
  "dictionaryEntries": *[
    _type == "dictionaryEntry"
  ]
    | order(title asc)
  {
    ${dictionaryEntryPreviewFragment}
  },
`

const dictionaryEntryHeaderModuleFragment = groq`
  $dictionaryEntrySlug != null => {
    "entry": *[
      _type == "dictionaryEntry" &&
      slug.current == $dictionaryEntrySlug
    ]
      | order(_updatedAt desc)
    [0] {
      ${dictionaryEntryPreviewFragment}
    },
  },
  options {
    ${dictionaryEntryOptionsFragment}
  },
`

const dictionaryEntryBodyModuleFragment = groq`
  content[] {
    ${complexContentFragment}
  },
`

const teamMembersGridModuleFragment = groq`
  title,
  subtitle,
  "teamMembers": teamMembers[]->{
    name,
    fullName,
    "slug": slug.current,
    jobTitle,
    about,
    linkedInUrl,
    blogAuthor->{
      "slug": slug.current,
    },
    photo {
      ${imageFragment}
    },
  },
`

const openRolesGridModuleFragment = groq`
  ...,
  openRoles[]->{
    ${openRolePreviewFragment}
  },
`

const openRoleHeaderModuleFragment = groq`
  $openRoleSlug != null => {
    "openRole": *[
      _type == "openRole" &&
      slug.current == $openRoleSlug &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      ${openRoleFragment}
    },
  },
  options {
    ${openRoleOptionsFragment}
  },
`

const openRoleBodyModuleFragment = groq`
  content[] {
    ${complexContentFragment}
  },
`

const baseModuleFragment = groq`
  _key,
  _type,
  _type == 'imageAndText' => {
    ${imageAndTextModuleFragment}
  },
  _type == 'grid' => {
    ${gridModuleFragment}
  },
  _type == 'mediaHero' => {
    ${mediaHeroModuleFragment}
  },
  _type == 'hero' => {
    ${heroModuleFragment}
  },
  _type == 'dividerPhoto' => {
    ${dividerPhotoModuleFragment}
  },
  _type == 'video' => {
    ${videoFragment}
  },
  _type == 'marquee' => {
    ${marqueeModuleFragment}
  },
  _type == 'logoGrid' => {
    ${logoGridModuleFragment}
  },
  _type == 'blogPostGrid' => {
    ${blogPostGridModuleFragment}
  },
  _type == 'blogPostHeader' => {
    ${blogPostHeaderModuleFragment}
  },
  _type == 'blogPostItem' => {
    ${blogPostItemModuleFragment}
  },
  _type == 'blogPostBody' => {
    ${blogPostBodyModuleFragment}
  },
  _type == 'blogPostCommentWidget' => {
    ${blogPostCommentWidgetModuleFragment}
  },
  _type == 'blogPostReadMore' => {
    ${blogPostReadMoreModuleFragment}
  },
  _type == 'blogCategoryPostGrid' => {
    ${blogCategoryPostGridGridModuleFragment}
  },
  _type == 'blogAuthorPostGrid' => {
    ${blogAuthorPostGridModuleFragment}
  },
  _type == 'maskedCircleSection' => {
    ${maskedCircleSectionFragment}
  },
  _type == 'pricingPlans' => {
    ${pricingPlansModuleFragment}
  },
  _type == 'dictionaryEntryList' => {
    ${dictionaryEntryListModuleFragment}
  },
  _type == 'dictionaryEntryHeader' => {
    ${dictionaryEntryHeaderModuleFragment}
  },
  _type == 'dictionaryEntryBody' => {
    ${dictionaryEntryBodyModuleFragment}
  },
  _type == 'teamMembersGrid' => {
    ${teamMembersGridModuleFragment}
  },
  _type == 'openRolesGrid' => {
    ${openRolesGridModuleFragment}
  },
  _type == 'openRoleHeader' => {
    ${openRoleHeaderModuleFragment}
  },
  _type == 'openRoleBody' => {
    ${openRoleBodyModuleFragment}
  },
`

const predefinedPageModuleModuleFragment = groq`
  "pageModules": pageModule->pageModules[] {
    ${baseModuleFragment}
  },
`

export const moduleFragment = groq`
  ${baseModuleFragment}
  _type == 'predefinedPageModule' => {
    ${predefinedPageModuleModuleFragment}
  },
`
