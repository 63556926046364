import { groq } from 'next-sanity'

import { imageFragment } from './image'

export const openRolePreviewFragment = groq`
  "id": _id,
  title,
  "slug": slug.current,
  excerpt,
  image {
    ${imageFragment}
  },
`

export const openRoleFragment = groq`
  ${openRolePreviewFragment}
  datePosted,
  validThrough,
  jobLocation {
    country,
    region,
    locality,
    postOfficeBoxNumber,
    postalCode,
    streetAddress,
  },
  employmentType,
  baseSalary {
    currency,
    minValue,
    maxValue,
    unitText,
  },
`

export const openRoleOptionsFragment = groq`
  showExcerpt,
  showImage,
`
