import { groq } from 'next-sanity'

import { blogPostWithoutBodyFragment } from './blog'
import { openRoleFragment } from './careers'
import { dictionaryEntryPreviewFragment } from './dictionary'
import { moduleFragment } from './modules'
import { siteFragment } from './site'

export const allSlugsQuery = groq`
  *[
    _type == $type &&
    type != "template"
  ] {
    locale,
    "slug": slug.current,
  }
`

export const sitemapPagesQuery = groq`
  *[
    _type == $type &&
    blockCrawling != true &&
    type != "template"
  ] {
    _type,
    "slug": slug.current,
  }
`

export const sitemapPagesWithLocaleQuery = groq`
  *[
    _type == $type &&
    locale in $locales &&
    blockCrawling != true &&
    type != "template"
  ] {
    _type,
    locale,
    "slug": slug.current,
  }
`

export const sitemapStaticPagesQuery = groq`
  *[
    _type in $types &&
    locale in $locales &&
    blockCrawling != true
  ] {
    _type,
    locale,
  }
`

export const crawlingBlockedPagesQuery = groq`
  *[
    blockCrawling == true &&
    type != "template"
  ] {
    _type,
    locale,
    "slug": slug.current,
  }
`

export const homePageQuery = groq`
  {
    "page": *[
      _type == "homePage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${moduleFragment}
      },
      hasTransparentHeader,
      seo,
      noIndex,
      blockCrawling,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const homePageModuleQuery = groq`
  *[
    _type == "homePage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const errorPageQuery = groq`
  {
    "page": *[
      _type == "errorPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${moduleFragment}
      },
      seo,
      noIndex,
      blockCrawling,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const errorPageModuleQuery = groq`
  *[
    _type == "errorPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const pageQuery = groq`
  {
    "page": *[
      _type == "page" &&
      slug.current in $pageSlugs &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      modules[] {
        ${moduleFragment}
      },
      type,
      hasTransparentHeader,
      seo,
      webPageSchema,
      noIndex,
      blockCrawling,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const pageModuleQuery = groq`
  *[
    _type == "page" &&
    slug.current in $pageSlugs &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const blogPostPageQuery = groq`
  {
    "page": *[
      _type == "blogPost" &&
      type != "template" &&
      slug.current == $blogPostSlug
    ]
      | order(_updatedAt desc)
    [0] {
      type,
      hasTransparentHeader,
      seo,
      noIndex,
      blockCrawling,
      modules[] {
        ${moduleFragment}
      },
      "slug": slug.current,
      "blogPage": *[
        _type == "blogPage"
      ]
        | order(_updatedAt desc)
      [0] {
        seo,
      },
      ${blogPostWithoutBodyFragment}
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const blogPostPageModuleQuery = groq`
  *[
    _type == "blogPost" &&
    type != "template" &&
    slug.current == $blogPostSlug
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const blogPageQuery = groq`
  {
    "page": *[
      _type == "blogPage"
    ]
      | order(_updatedAt desc)
    [0] {
      hasTransparentHeader,
      seo,
      noIndex,
      blockCrawling,
      modules[] {
        ${moduleFragment}
      },
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const blogPageModuleQuery = groq`
  *[
    _type == "blogPage"
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const blogCategoryPageQuery = groq`
  {
    "page": *[
      _type == "blogCategory" &&
      slug.current == $blogCategorySlug
    ]
      | order(_updatedAt desc)
    [0] {
      hasTransparentHeader,
      seo,
      noIndex,
      blockCrawling,
      modules[] {
        ${moduleFragment}
      },
      "slug": slug.current,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const blogCategoryPageModuleQuery = groq`
  *[
    _type == "blogCategory" &&
    slug.current == $blogCategorySlug
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const blogAuthorPageQuery = groq`
  {
    "page": *[
      _type == "blogAuthor" &&
      slug.current == $blogAuthorSlug
    ]
      | order(_updatedAt desc)
    [0] {
      hasTransparentHeader,
      seo,
      noIndex,
      blockCrawling,
      modules[] {
        ${moduleFragment}
      },
      "slug": slug.current,
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const blogAuthorPageModuleQuery = groq`
  *[
    _type == "blogAuthor" &&
    slug.current == $blogAuthorSlug
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const dictionaryEntryPageQuery = groq`
  {
    "page": *[
      _type == "dictionaryEntry" &&
      slug.current == $dictionaryEntrySlug
    ]
      | order(_updatedAt desc)
    [0] {
      ${dictionaryEntryPreviewFragment}
      hasTransparentHeader,
      seo,
      noIndex,
      blockCrawling,
      "dictionaryPage": *[
        _type == "dictionaryPage"
      ]
        | order(_updatedAt desc)
      [0] {
        seo,
      },
      modules[] {
        ${moduleFragment}
      },
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const dictionaryEntryPageModuleQuery = groq`
  *[
    _type == "dictionaryEntry" &&
    slug.current == $dictionaryEntrySlug
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const dictionaryPageQuery = groq`
  {
    "page": *[
      _type == "dictionaryPage"
    ]
      | order(_updatedAt desc)
    [0] {
      hasTransparentHeader,
      seo,
      noIndex,
      blockCrawling,
      modules[] {
        ${moduleFragment}
      },
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const dictionaryPageModuleQuery = groq`
  *[
    _type == "dictionaryPage"
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const openRolePageQuery = groq`
  {
    "page": *[
      _type == "openRole" &&
      slug.current == $openRoleSlug &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      ${openRoleFragment}
      hasTransparentHeader,
      seo,
      noIndex,
      blockCrawling,
      "careersPage": *[
        _type == "careersPage" &&
        locale == $locale
      ]
        | order(_updatedAt desc)
      [0] {
        seo,
      },
      modules[] {
        ${moduleFragment}
      },
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const openRolePageModuleQuery = groq`
  *[
    _type == "openRole" &&
    slug.current == $openRoleSlug &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`

export const careersPageQuery = groq`
  {
    "page": *[
      _type == "careersPage" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      hasTransparentHeader,
      seo,
      noIndex,
      blockCrawling,
      modules[] {
        ${moduleFragment}
      },
    },
    "site": {
      ${siteFragment}
    },
  }
`

export const careersPageModuleQuery = groq`
  *[
    _type == "careersPage" &&
    locale == $locale
  ]
    | order(_updatedAt desc)
  [0].modules[] {
    ${moduleFragment}
  }
`
