import { groq } from 'next-sanity'

import { imageFragment } from './image'
import { buttonStyleFragment, linkPageFragment } from './link'
import { videoFragment } from './video'

const markDefFragment = groq`
  ...,
  _type == "link" => {
    isButton == true => {
      "buttonStyles": buttonStyle {
        ${buttonStyleFragment}
      },
    },
    page->{
      ${linkPageFragment}
    },
  },
`

export const simpleContentFragment = groq`
  ...,
  markDefs[] {
    ${markDefFragment}
  },
`

export const complexContentFragment = groq`
  ...,
  markDefs[] {
    ${markDefFragment}
  },
  _type == "figure" => {
    ${imageFragment}
  },
  _type == "videoEmbed" => {
    muxVideo {
      asset->,
    },
    subtitles {
      ...,
      "url": asset->url,
    },
  },
  _type == "videoButton" => {
    thumbnail {
      ${imageFragment}
    },
    video {
      ${videoFragment}
    },
    style {
      ${buttonStyleFragment}
    },
  },
  _type == 'infoBox' => {
    content[] {
      ${simpleContentFragment}
    },
  },
  _type == 'lottieAnimation' => {
    "assetUrl": file.asset->url,
  },
`
