import { groq } from 'next-sanity'

import { newsletterFragment } from './blocks'
import { simpleContentFragment } from './content'
import { imageFragment } from './image'
import { linkFragment, linkPageFragment } from './link'

const headerDesktopMenuFragment = groq`
  "slug": slug.current,
  items[] {
    ${linkFragment}
    dropdownItems[] {
      ${linkFragment}
    },
  },
`

const headerMobileMenuFragment = groq`
  "slug": slug.current,
  items[] {
    ${linkFragment}
    dropdownItems[] {
      ${linkFragment}
    },
  },
`

const headerPromoFragment = groq`
  enabled,
  display,
  text,
  "link": link->{
    ${linkPageFragment}
  },
`

const headerFragment = groq`
  menuDesktopLeft->{
    ${headerDesktopMenuFragment}
  },
  menuDesktopRight->{
    ${headerDesktopMenuFragment}
  },
  menuMobilePrimary->{
    ${headerMobileMenuFragment}
  },
  menuMobileSecondary->{
    ${headerMobileMenuFragment}
  },
  "promo": *[
    _type == "promoSettings" &&
    locale == $locale
  ][0] {
    ${headerPromoFragment}
  },
`

const footerBlock1Fragment = groq`
  "title": blockTitle1,
  "description": blockDescription1[] {
    ${simpleContentFragment}
  },
  newsletter {
    ${newsletterFragment}
  },
`

const footerBlock2Fragment = groq`
  "title": blockTitle2,
  "menu": blockMenu2->{
    items[] {
      ${linkFragment}
    },
  },
`

const footerBlock3Fragment = groq`
  "title": blockTitle3,
  "menu": blockMenu3->{
    items[] {
      ${linkFragment}
    },
  },
`

const footerBlock4Fragment = groq`
  "title": blockTitle4,
  "description": blockDescription4[] {
    ${simpleContentFragment}
  },
  social[] {
    _key,
    icon,
    url,
  },
`

const footerFragment = groq`
  "blocks": [
    {
      ${footerBlock1Fragment}
    },
    {
      ${footerBlock2Fragment}
    },
    {
      ${footerBlock3Fragment}
    },
    {
      ${footerBlock4Fragment}
    }
  ],
  copyright,
  paymentMethods[] {
    ${imageFragment}
  },
`

const cookieConsentFragment = groq`
  enabled,
  message,
  "link": link->{
    ${linkPageFragment}
  },
`

const seoFragment = groq`
  siteTitle,
  metaTitle,
  metaDesc,
  shareTitle,
  shareDesc,
  shareGraphic,
`

const schemaSettingsFragment = groq`
  organization {
    name,
    url,
    logo,
    sameAsUrls[],
    phone,
    email,
    address {
      country,
      region,
      locality,
      postOfficeBoxNumber,
      postalCode,
      streetAddress,
    },
  },
`

const siteStringsFragment = groq`
  loadingPageTitle,
  languageSwitch,
  skipToContent,
  goHomeLabel,
  mobileMenuLabel,
  carouselCounterText,
  carouselLeftArrowLabel,
  carouselRightArrowLabel,
  carouselDotLabel,
  buttonAccept,
  buttonLearnMore,
  buttonTryAgain,
  buttonLoadMore,
  buttonSubmitting,
  buttonCancel,
  emailAddress,
  emailAddressPlaceholder,
  emailMissing,
  emailInvalid,
  firstName,
  firstNamePlaceholder,
  firstNameMissing,
  lastName,
  lastNamePlaceholder,
  lastNameMissing,
  fullName,
  fullNamePlaceholder,
  fullNameMissing,
  phoneNumber,
  phoneNumberPlaceholder,
  phoneNumberMissing,
  phoneNumberInvalid,
  company,
  companyPlaceholder,
  companyMissing,
  dictionary,
  lastUpdated,
  blogPostReadMore,
  blogPostBy,
  teamMemberCardLinkedIn,
  teamMemberCardBlogArticles,
`

const identityFragment = groq`
  logo {
    ${imageFragment}
  },
  invertedLogo {
    ${imageFragment}
  },
`

const publicSettingsFragment = groq`
  siteURL,
  hubSpotPortalId,
  klaviyoAccountID,
  gtmContainerId,
  analyticsId,
  cookieBotId,
  disqusShortname,
  pageAnimation,
`

const allSettingsFragment = groq`
  hubSpotAccessToken,
  klaviyoPrivateKey,
  stampedStoreHash,
  ${publicSettingsFragment}
`

export const publicSiteSettingsQuery = groq`
  *[
    _type == "generalSettings" &&
    locale == $locale
  ][0] {
    ${publicSettingsFragment}
  }
`

export const allSiteSettingsQuery = groq`
  *[
    _type == "generalSettings" &&
    locale == $locale
  ][0] {
    ${allSettingsFragment}
  }
`

export const enabledLocalesQuery = groq`
  *[
    _type == "generalSettings" &&
    enabled == true
  ][].locale
`

export const siteFragment = groq`
  "publicLocales": *[
    _type == "generalSettings" &&
    enabled == true
  ][].locale,
  "settings": *[
    _type == "generalSettings" &&
    locale == $locale
  ][0] {
    ${publicSettingsFragment}
  },
  "identity": *[
    _type == "identitySettings" &&
    locale == $locale
  ][0] {
    ${identityFragment}
  },
  "cookieConsent": *[
    _type == "cookieSettings" &&
    locale == $locale
  ][0] {
    ${cookieConsentFragment}
  },
  "header": *[
    _type == "headerSettings" &&
    locale == $locale
  ][0] {
    ${headerFragment}
  },
  "footer": *[
    _type == "footerSettings" &&
    locale == $locale
  ][0] {
    ${footerFragment}
  },
  "seo": *[
    _type == "seoSettings" &&
    locale == $locale
  ][0] {
    ${seoFragment}
  },
  "schemaSettings": *[
    _type == "schemaSettings" &&
    locale == $locale
  ][0] {
    ${schemaSettingsFragment}
  },
  "siteStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${siteStringsFragment}
  },
`

export const siteQuery = groq`
  {
    ${siteFragment}
  }
`
