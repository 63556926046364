import { type SanityClient } from 'next-sanity'

import {
  allSlugsQuery,
  crawlingBlockedPagesQuery,
  errorPageQuery,
  homePageQuery,
  pageQuery,
  sitemapPagesQuery,
  sitemapPagesWithLocaleQuery,
  sitemapStaticPagesQuery,
} from '@data/sanity/queries/page'
import {
  type SanityAllSlugs,
  type SanityCrawlingBlockedPagesQuery,
  type SanityErrorPageQuery,
  type SanityHomePageQuery,
  type SanityPageQuery,
  type SanitySitemapPagesQuery,
  type SanitySitemapStaticPagesQuery,
} from '@data/sanity/queries/types/page'
import {
  type SanityBlogAuthorSlugQueryParameter,
  type SanityBlogCategorySlugQueryParameter,
  type SanityBlogPostSlugQueryParameter,
  type SanityDictionaryEntrySlugQueryParameter,
  type SanityDocumentType,
  type SanityLocaleQueryParameter,
  type SanityLocalesQueryParameter,
  type SanityOpenRoleSlugQueryParameter,
  type SanityPageSlugsQueryParameter,
  type SanityTypeQueryParameter,
  type SanityTypesQueryParameter,
} from '@data/sanity/queries/types/parameters'
import { type Locale } from '@lib/language'
import { fetchQuery } from './client'

export type SanityModuleQueryParameters = SanityBlogAuthorSlugQueryParameter &
  SanityBlogCategorySlugQueryParameter &
  SanityBlogPostSlugQueryParameter &
  SanityDictionaryEntrySlugQueryParameter &
  SanityOpenRoleSlugQueryParameter

export const defaultModuleQueryParameters: SanityModuleQueryParameters = {
  blogAuthorSlug: null,
  blogCategorySlug: null,
  blogPostSlug: null,
  dictionaryEntrySlug: null,
  openRoleSlug: null,
}

/**
 * Fetches all document slugs in all locales.
 */
export const getAllSlugs = async (
  sanityClient: SanityClient,
  type: SanityDocumentType
) =>
  fetchQuery<SanityAllSlugs, SanityTypeQueryParameter>(
    sanityClient,
    allSlugsQuery,
    {
      type,
    }
  )

/**
 * Fetches pages for sitemap.
 */
export const getSitemapPages = async (
  sanityClient: SanityClient,
  type: SanityDocumentType
) =>
  fetchQuery<SanitySitemapPagesQuery, SanityTypeQueryParameter>(
    sanityClient,
    sitemapPagesQuery,
    {
      type,
    }
  )

/**
 * Fetches pages for sitemap for given locales.
 */
export const getSitemapPagesWithLocale = async (
  sanityClient: SanityClient,
  type: SanityDocumentType,
  locales: Locale[]
) =>
  fetchQuery<
    SanitySitemapPagesQuery,
    SanityTypeQueryParameter & SanityLocalesQueryParameter
  >(sanityClient, sitemapPagesWithLocaleQuery, {
    type,
    locales,
  })

/**
 * Fetches static pages for sitemap.
 */
export const getSitemapStaticPages = async (
  sanityClient: SanityClient,
  locales: Locale[]
) =>
  fetchQuery<
    SanitySitemapStaticPagesQuery,
    SanityLocalesQueryParameter & SanityTypesQueryParameter
  >(sanityClient, sitemapStaticPagesQuery, {
    locales,
    types: ['homePage', 'blogPage', 'dictionaryPage'],
  })

/**
 * Fetches crawling-blocked pages for robots.
 */
export const getCrawlingBlockedPages = async (sanityClient: SanityClient) =>
  fetchQuery<SanityCrawlingBlockedPagesQuery>(
    sanityClient,
    crawlingBlockedPagesQuery
  )

/**
 * Fetches the home page.
 */
export const getHomePage = async (sanityClient: SanityClient, locale: Locale) =>
  fetchQuery<
    SanityHomePageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, homePageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the error page.
 */
export const getErrorPage = async (
  sanityClient: SanityClient,
  locale: Locale
) =>
  fetchQuery<
    SanityErrorPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, errorPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches a specific page based on slug.
 */
export const getPage = async (
  sanityClient: SanityClient,
  locale: Locale,
  slug: string
) =>
  fetchQuery<
    SanityPageQuery,
    SanityLocaleQueryParameter &
      SanityPageSlugsQueryParameter &
      SanityModuleQueryParameters
  >(sanityClient, pageQuery, {
    ...defaultModuleQueryParameters,
    locale,
    pageSlugs: [`/${slug}`, slug, `/${slug}/`],
  })
