import { groq } from 'next-sanity'

export const dictionaryEntryPreviewFragment = groq`
  "id": _id,
  title,
  "slug": slug.current,
  excerpt,
  "lastUpdatedDate": coalesce(lastUpdated, _createdAt),
`

export const dictionaryEntryOptionsFragment = groq`
  showDate,
  showExcerpt,
`
