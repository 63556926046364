import { useLiveQuery } from '@sanity/preview-kit'

import { type SanityModule } from '@data/sanity/queries/types/modules'

import Modules from './modules'

interface PreviewModulesProps {
  modules: SanityModule[] | null
  moduleQuery: string
  moduleQueryParams?: Record<string, any>
}

const PreviewModules = ({
  modules,
  moduleQuery,
  moduleQueryParams,
}: PreviewModulesProps) => {
  const [liveModules] = useLiveQuery(modules, moduleQuery, moduleQueryParams)

  return <Modules modules={liveModules} />
}

export default PreviewModules
