import { groq } from 'next-sanity'

import { blogPostWithoutBodyFragment, blogPostOptionsFragment } from './blog'
import { complexContentFragment, simpleContentFragment } from './content'
import { imageFragment } from './image'
import { buttonStyleFragment } from './link'
import { videoFragment } from './video'

export const newsletterFragment = groq`
  service,
  hubSpotFormId,
  klaviyoListID,
  variant,
  title,
  description,
  submit,
  successMsg[] {
    ${complexContentFragment}
  },
  errorMsg[] {
    ${complexContentFragment}
  },
  terms[] {
    ${simpleContentFragment}
  },
`

const freeformBlockFragment = groq`
  textAlign,
  maxWidth,
  content[] {
    ${complexContentFragment}
  },
`

const accordionsBlockFragment = groq`
  items[] {
    title,
    "id": _key,
    content[] {
      ${simpleContentFragment}
    },
  },
`

const blogPostCardBlockFragment = groq`
  post->{
    ${blogPostWithoutBodyFragment}
  },
  options {
    ${blogPostOptionsFragment}
  },
`

const contentCarouselBlockCarouselFragment = groq`
  items[] {
    _key,
    _type,
    _type == 'freeform' => {
      "freeform": {
        ${freeformBlockFragment}
      },
    },
    _type == 'photo' => {
      "photo": {
        ${imageFragment}
      },
    },
    _type == 'blogPost' => {
      "blogPost": *[
        _type == "blogPost" &&
        _id == ^._ref
      ][0] {
        ${blogPostWithoutBodyFragment}
      },
    },
    _type == 'video' => {
      "video": {
        ${videoFragment}
      },
    },
  },
`

const calendlyWidgetFragment = groq`
  url,
  hideEventDetails,
  hideCookieSettings,
`

const cookieDeclarationFragment = groq`
  "cookieBotId": *[
    _type == "generalSettings" &&
    locale == $locale
  ][0].cookieBotId,
  active,
`

export const demoFormFragment = groq`
  service,
  hubSpotFormId,
  hubSpotNewsletterFormId,
  klaviyoListID,
  klaviyoNewsletterListID,
  submit,
  analytics {
    submitEventName,
  },
  strings,
  successMsg[] {
    ${complexContentFragment}
  },
  errorMsg[] {
    ${complexContentFragment}
  },
  terms[] {
    ${simpleContentFragment}
  },
  newsletterStatement[] {
    ${simpleContentFragment}
  },
  buttonStyle {
    ${buttonStyleFragment}
    fontCase,
  },
`

const htmlBlockFragment = groq`
  code
`

const iframeBlockFragment = groq`
  title,
  url,
  width,
  height,
`

const teamMemberCardFragment = groq`
  teamMember->{
    name,
    fullName,
    "slug": slug.current,
    jobTitle,
    about,
    linkedInUrl,
    blogAuthor->{
      "slug": slug.current,
    },
    photo {
      ${imageFragment}
    },
  },
`

const horizontalTeamMemberCardFragment = groq`
  teamMember->{
    name,
    fullName,
    "slug": slug.current,
    jobTitle,
    about,
    linkedInUrl,
    blogAuthor->{
      "slug": slug.current,
    },
    photo {
      ${imageFragment}
    },
  },
`

const baseBlockFragment = groq`
  _key,
  _type,
  _type == 'freeform' => {
    ${freeformBlockFragment}
  },
  _type == 'accordions' => {
    ${accordionsBlockFragment}
  },
  _type == 'video' => {
    ${videoFragment}
  },
  _type == 'newsletter' => {
    ${newsletterFragment}
  },
  _type == 'blogPostCard' => {
    ${blogPostCardBlockFragment}
  },
  _type == 'contentCarousel' => {
    ${contentCarouselBlockCarouselFragment}
  },
  _type == 'calendlyWidget' => {
    ${calendlyWidgetFragment}
  },
  _type == 'cookieDeclaration' => {
    ${cookieDeclarationFragment}
  },
  _type == 'demoForm' => {
    ${demoFormFragment}
  },
  _type == 'html' => {
    ${htmlBlockFragment}
  },
  _type == 'iframe' => {
    ${iframeBlockFragment}
  },
  _type == 'teamMemberCard' => {
    ${teamMemberCardFragment}
  },
  _type == 'horizontalTeamMemberCard' => {
    ${horizontalTeamMemberCardFragment}
  },
 `

const predefinedContentBlockFragment = groq`
  "contentBlocks": contentBlock->contentBlocks[] {
    ${baseBlockFragment}
  },
`

export const blockFragment = groq`
  ${baseBlockFragment}
  _type == 'predefinedContentBlock' => {
    ${predefinedContentBlockFragment}
  },
 `
