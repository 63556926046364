import { groq } from 'next-sanity'

const imageDimensionsFragment = groq`
  width,
  height,
  aspectRatio,
`

export const imageFragment = groq`
  asset,
  crop,
  hotspot,
  customRatio,
  alt,
  caption,
  fixedHeight,
  priority,
  borderRadius,
  "id": asset->assetId,
  "type": asset->mimeType,
  "dimensions": asset->metadata.dimensions {
    ${imageDimensionsFragment}
  },
`
